export default {
  //productiom
  BASE_URL: "https://api.ibidz.in/",
  BASE_URL_IMG: "https://admin.ibidz.in/",

  curency: "INR",
  countryCode: "en-IN",
  minFractionDigits: 0,
  RefreshAuctionPage: 60000, // 1000 = 1 sec
  pagelaoddelay: 2, //  1 = 1  sec
  pageRedirectDelay: 3, // 1 = 1 sec
  toastDelay: 5, // 1 = 1 sec
  // rpayKey: "rzp_test_cWQhGghXCxe6cO",  // test old
  rpayKey: "rzp_test_jW0cPc3T1WM5a8",
  //CurrNttimezone: "Asia/Dubai",

  CurrNttimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,

  copyright: " Developed by Ibidz" + "",
  copyrighturl: "#",
  IMG_INDEX_COUNT: 2,
};
