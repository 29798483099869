<template>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
          <div class="container containerDash">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sidebar-dashboard-customer></sidebar-dashboard-customer>
              </div>
              <div class="col-lg-9">
                <h3>My Winnings</h3>

                <div v-if="!customerList.length">
                  <notfound :checkdata="initialLoading"></notfound>
                </div>
                <div v-else>
                  <div v-if="customerList.length" class="noPrint" style="padding-left: 14px">
                    <div v-if="totalCliamAmount">
                      <div class="row">
                        <div class="col-10" style="padding: 0px">
                          <button class="btn btnClaim" style="margin-right: 10px" disabled>
                            Redeem<span v-if="claimStatus == true">ed</span>
                            <span v-else>able</span> Amount RS
                            {{ totalCliamAmount | currency }}
                          </button>
                          <!-- <button
                            class="btn btn-warning"
                            v-if="claimStatus != true"
                            @click="redeem(claimAmount)"
                            style="margin-right: 10px"
                          >
                            <span v-if="claimStatus == true"></span> Claim<span
                              v-if="claimStatus == true"
                              >ed</span
                            >
                          </button> -->
                        </div>
                        <div class="col-2">
                          <button class="btn btn-primary" style="float: right" @click="printWindow">
                            Print
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <section style="
                      margin-top: 10px;
                      padding: 0px;
                      border-radius: 5px;
                      background-color: #f1f1f1;
                    " class="autionlog-table container">
                    <!-- <selectioncontainer></selectioncontainer> -->
                    <div class="">
                      <div class="table-cont">
                        <div class="rowz headerzz">
                          <div class="cellz">Id</div>
                          <div class="cellz">Auction Name</div>

                          <div class="cellz">Win Amount</div>
                          <!-- <div class="cellz">Model Name</div> -->
                          <div class="cellz">Token Amount</div>
                          <div class="cellz">Payment Status</div>
                          <div class="cellz">Balance Amount</div>
                          <div class="cellz">Verification</div>
                          <div class="cellz">Balance Status</div>

                          <div class="cellz">Auction Status</div>
                        </div>

                        <div class="rowz" v-for="list in customerList" :key="list.aucId">
                          <div class="cellz" data-title="Id">
                            {{ list.aucId }}
                          </div>

                          <div class="cellz" data-title="Auction Name">
                            <router-link @click="gotoPackagePage" class="line-clamp" v-if="
                              list.saleStatus == null ||
                              list.saleStatus == 'SaleConfirm'
                            " :to="{
                              name: 'wonDetails',
                              params: { url_key: list.aucName },
                            }">
                              {{ list.aucName }}
                            </router-link>

                            <p v-else disabled class="line-clamp">
                              {{ list.aucName }}
                            </p>
                          </div>

                          <div class="cellz" data-title="Bid Amount">
                            {{ list.latestBidAmount | currency }}
                          </div>

                          <div class="cellz" data-title="Token Amount">
                            {{ list.tokenAmount | currency }}
                          </div>
                          <div class="cellz" data-title="Payment Status">
                            <p v-if="list.paymentStatus == 1">
                              <i class="fa fa-check-square"></i> Paid
                            </p>
                            <p v-else>
                              <button style="width: 85px" @click="payToken(list)" class="btn-invoice">
                                Pay
                              </button>
                            </p>
                          </div>
                          <div class="cellz" data-title="Balance Amount">
                            <p>{{ list.balance | currency }}</p>
                          </div>
                          <div class="cellz" data-title="Verification">
                            <div v-if="
                              list.paymentStatus == true &&
                              list.saleStatus !== 'Rejected'
                            ">
                              <p v-if="list.aucWonOtp && list.otpStatus != true">
                                <button class="btn btn-primary detailsBtn" disabled>
                                  Otp - {{ list.aucWonOtp }}
                                </button>
                              </p>
                              <p v-else-if="list.otpStatus == true">
                                <button class="btn btn-success" style="width: 100%" disabled>
                                  <i class="fa fa-check-square"></i>
                                  Verified
                                </button>
                              </p>
                              <p v-else>
                                <button :disabled="loading" class="btn btn-primary detailsBtn"
                                  @click="generateOtp(list)" data-toggle="tooltip" data-placement="top"
                                  title="! Please generate otp for vehicle verification.">
                                  <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                  <span>Generate OTP</span>
                                </button>
                              </p>
                            </div>
                            <div v-else-if="list.saleStatus == 'Rejected'">
                              <button class="btn" style="width: 100%; cursor: not-allowed" disabled>
                                <i class="fa fa-window-close"></i>
                                Rejected
                              </button>
                            </div>
                            <div v-else>
                              <button class="btn btn-danger" style="width: 100%" disabled data-toggle="tooltip"
                                data-placement="top" title="! Please pay the token amount to view seller details.">
                                <i class="fa fa-window-close"></i>
                                Not Verified
                              </button>
                            </div>
                          </div>
                          <div class="cellz" data-title="Payment Status">
                            <p v-if="
                              list.paymentStatus == true &&
                              list.saleStatus != 'Rejected' &&
                              list.saleStatus == 'SaleConfirm'
                            ">
                              <i class="fa fa-check-square"></i> Paid
                            </p>
                            <p v-else>Pending</p>
                          </div>

                          <div class="cellz" data-title="Auction Status">
                            <button v-if="
                              list.paymentStatus == true &&
                              list.saleStatus == null && list.otpStatus != null
                            " class="btn-invoice" style="background-color: #e91212; width: 85px"
                              @click="rejectAuction(list)">
                              Reject
                            </button>
                            <button v-if="
                              list.paymentStatus == true &&
                              list.saleStatus == 'Rejected'
                            " class="btn-invoice" style="
                                background-color: rgb(111, 95, 95);
                                width: 85px;
                                cursor: no-drop;
                              " disabled>
                              Rejected
                            </button>

                            <button v-if="
                              list.paymentStatus == true &&
                              list.saleStatus == 'SaleConfirm'
                              && list.ReviewID !== null
                            " class="btn-invoice" style="
                                background-color: green;
                                width: 85px;
                                cursor: no-drop;
                              " disabled>
                              Completed
                            </button>

                            <div  v-if="
                              list.paymentStatus == true &&
                              list.saleStatus == 'SaleConfirm' 
                              && list.ReviewID == null">
                            
                            
                            <button
                                class="btn btn-primary"
                                @click="showBuyerModal = true"
                              >
                                Add Review
                              </button>
                              <div>
                                <b-modal
                                  v-model="showBuyerModal"
                                  title="Add Seller Review"
                                  hide-footer
                                  @hide="onModalHide"
                                >
                                  <div class="modal-body">
                                    <h5>
                                      <strong>{{ list.aucName }}</strong>
                                    </h5>
                                    <div class="rating">
                                      <label>Rating:</label>
                                      <vue-star-rating
                                        v-model="rating"
                                        :star-size="25"
                                      ></vue-star-rating>
                                    </div>
                                    <div class="form-group">
                                      <label>Comment:</label>
                                      <textarea
                                        v-model="comment"
                                        class="form-control"
                                        rows="3"
                                      ></textarea>
                                    </div>
                                    <button
                                      @click="submitReview(list.aucId)"
                                      class="btn btn-primary mt-3"
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </b-modal>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-mask" v-if="showModal == true">
                      <div class="modal-container modalRejection">
                        <button class="close" @click="modelClose()">x</button>
                        <div class="modal-header">
                          <h3>! Warning</h3>
                        </div>

                        <div class="modal-body">
                          <label class="form-label">
                            Reason for rejection
                            <textarea v-model="rejectDetails.rejectReason" rows="3" class="form-control"></textarea>
                          </label>
                          <label for="validate" v-if="message == 'Success'" style="color: rgb(70 185 127)"><Strong>{{
                            message }}</Strong>
                          </label>
                          <label for="validate" v-else style="color: rgb(233 119 119)">{{ message }}</label>
                        </div>

                        <div class="modal-footer text-right">
                          <button style="width: 85px" class="btn btn-danger"
                            @click="updCustomerSaleStatus(rejectDetails)">
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="modal-mask" v-if="packageModal == true">
                      <div class="modal-container modal-lg">
                        <button class="close" @click="packageModalClose()">
                          x
                        </button>
                        <div class="modal-header">
                          <h3>Redeem Package</h3>
                        </div>

                        <div class="modal-body cust-scroll-l">
                          <div style="text-align: end">
                            <h4>
                              Token Amount :
                              {{ selectedTokenAmount | currency }}
                            </h4>
                          </div>
                          <div style="text-align: center" v-if="packageClaimList.length == 0">
                            <p class="nopkgAvail">
                              No packages available for redeem
                            </p>
                          </div>
                          <table v-if="packageClaimList.length > 0">
                            <thead>
                              <tr>
                                <th>Pack Name</th>
                                <th>Pack Amount</th>
                                <th style="width: 265px">Pack Date</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="item in packageClaimList" :key="item.phHistoryId" :class="highlightRow(item)">
                                <td>{{ item.packName }}</td>
                                <td>{{ item.packAmount | currency }}</td>
                                <td>{{ item.packDate | formatDate }} IST</td>
                                <td>
                                  <input type="checkbox" :value="item.phHistoryId" name="" v-model="selectedPackages"
                                    @change="getSelectedPackages()" id="selectedPack" />
                                </td>
                              </tr>
                            </tbody>
                          </table>


                        </div>

                        <div class="modal-footer text-right">

                          <div class="redeemPkgStatic" v-if="selectedPackages.length > 0">
                            <label class="form-label" v-if="claimAmount < selectedTokenAmount">
                              Amount {{ claimAmount | currency }} will reduced
                              from token amount. Amount to pay
                              {{
                                (selectedTokenAmount - claimAmount) | currency
                              }}
                            </label>
                            <label class="form-label" v-else for="" style="color: #e91212">
                              Claim amount cannot exceed token amount
                              {{ selectedTokenAmount | currency }} .
                            </label>
                          </div>

                          <button class="btn btn-info" @click="clearClaimed()" v-if="claimStatus == true"
                            style="margin-right: 10px">
                            Clear
                          </button>
                          <button v-if="
                            isClaimbleCheck(claimAmount, selectedTokenAmount)
                          " class="btn btn-success" style="float: right" :disabled="loading" @click="
                            reduceClaimAmount(
                              claimAmount,
                              selectedAuctionDetails
                            )
                            ">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Continue</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import userService from "@/services/user.service";
import Config from "@/config";
import moment from "moment";
import VueStarRating from "vue-star-rating";
export default {
  props: ["order_details", "customer_details"],
  data() {
    return {
      customerList: "",
      path: Config.BASE_URL_IMG,
      initialLoading: true,
      paymentInfo: "",
      script: `https://checkout.razorpay.com/v1/checkout.js`,
      modelShow: false,
      message: "",
      showModal: false,
      packageModal: false,
      claimAmount: 0,
      claimStatus: false,
      packageClaimList: [],
      selectedPackages: [],
      totalSelectedAmount: 0,
      selectedTokenAmount: 0,
      totalCliamAmount: 0,
      st: "",
      isClaimble: true,
      selectedAuctionDetails: [],
      loading: false,
      Salemessage: "",
      toastCount: 0,
      showBuyerModal: false,
      rating: 0,
      comment: "",
    };
  },
  created() {
    if (this.loggedIn) {
      if (
        !this.$store.state.auth.user[0].customerId == "" ||
        !this.$store.state.auth.user[0].customerId == " "
      ) {
        this.$router.push("/MyWinnings").catch(() => {
          "home";
        });
      } else {
        this.$router.push("/").catch(() => {
          "home";
        });
      }
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  components: {
    "vue-star-rating": VueStarRating,
  },
  mounted() {
    if (this.$store.state.auth.user) {
      this.st = this.$store.state.auth.user[0];

      this.getCustomerWinnings(this.st.customerId);
      this.getclaimamount(this.st.customerId);
    } else {
      this.$router.push("/").catch(() => {
        "myaccount";
      });
    }
  },

  methods: {
    onModalHide() {
      this.clearModalData();
    },
    submitReview(aucid) {
      // Handle save for seller
      this.isLoading = true;
      console.log(aucid);
      
      userService
        .addUserReview(
          "INS",
          aucid,
          this.comment,
          this.rating,
          this.st.customerId,
          "Buyer"
        )
        .then((response) => {
          this.Salemessage = response.data.Message;
          if (this.Salemessage == "Success") {
            this.clearModalData();
            this.makeToast("success", "Updated successfully");
            setTimeout(function () {
              location.reload();
            }, Config.pageRedirectDelay * 1000);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.message =
            (error.response && error.response.data.Message) ||
            error.message ||
            error.toString();

          this.makeToast("warning", this.message);
        });
    },
    clearModalData() {
      // Clear form fields when modal is closed
      this.rating = 0;
      this.comment = "";
      this.showBuyerModal = false;
    },
    gotoPackagePage() {
      // Set the returnUrl value in session storage
      sessionStorage.setItem("returnUrl", "/MyWinnings");
      // this.$router.push('/package');
    },
    highlightRow(item) {


      if (item.isActive == 1) {
        return "highlighted-row";
      }

      return "";
    },
    routTo() {
      location.reload();
      // this.$router
      //   .push({
      //     name: "wonDetails",
      //     params: { url_key: urlKey },
      //   })
      //   .catch((error) => {
      //     console.error("Error navigating to /wonDetails:", error);
      //   });
    },
    generateOtp(auctionifos) {
      this.loading = true;
      let cusid = this.st.customerId;
      userService
        .generateWonOtp(auctionifos, cusid)
        .then((response) => {
          // console.log(response.data.Message);
          if (response.data.Message == "Success") {
            this.loading = false;
            this.makeToast("success", "Otp generated successfully");
          }
          setTimeout(() => {
            location.reload();
          }, 2000);
        })
        .catch((error) => {
          this.isLoading = false;
          this.loading = false;
          let resMessage =
            (error.response && error.response.data.Message) ||
            error.message ||
            error.toString();
          this.makeToast("danger", resMessage);
        });
    },
    packageModalClose() {
      this.packageModal = false;
      this.selectedPackages = [];
      this.loading = false;
    },
    printWindow() {
      window.print();
    },
    rejectAuction(list) {
      this.showModal = true;
      this.rejectDetails = list;
    },
    modelHide() {
      this.message = "";
      this.showModal = false;
      this.rejectDetails.rejectReason = "";
      location.reload();
    },

    modelClose() {
      this.message = "";
      this.showModal = false;
    },
    updCustomerSaleStatus(rejectDetails) {
      if (rejectDetails.rejectReason) {
        let cusid = this.st.customerId;
        // console.log(rejectDetails, cusid);
        userService
          .updCustomerSaleStatus(cusid, rejectDetails)
          .then((response) => {
            let resMessage = response.data.Message;
            if (resMessage == "Success") {
              this.makeToast("success", "Rejected successfully");
              var self = this;
              setTimeout(function () {
                self.modelHide();
              }, 2000);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            let resMessage =
              (error.response && error.response.data.Message) ||
              error.message ||
              error.toString();

            this.makeToast("danger", resMessage);
          });
      } else {
        this.message = "Enter reason for rejection ";
      }
    },
    isClaimbleCheck(claim, tokenAmount) {
      // console.log("claim : ", claim, "token : ", tokenAmount);
      let isClaim = true;
      if (claim > tokenAmount) {
        isClaim = false;
      }

      return isClaim;
    },
    getSelectedPackages() {
      // Perform further actions with the selected packages
      if (this.selectedPackages.length > 0) {
        // Get the data of the selected packages based on their IDs
        const selectedPackagesData = this.packageClaimList.filter((item) =>
          this.selectedPackages.includes(item.phHistoryId)
        );

        // Calculate the total amount of the selected packages
        const totalSelectedAmount = selectedPackagesData.reduce(
          (total, item) => total + item.packAmount,
          0
        );

        this.claimAmount = totalSelectedAmount;
        this.claimStatus = true;
      }
    },

    reduceClaimAmount(claimAmount) {
      this.loading = true;
      this.payTokenAmount(claimAmount, this.selectedAuctionDetails);
    },
    clearClaimed() {
      this.claimStatus = false;
      this.selectedPackages = [];
      this.claimAmount = 0;
      this.loading = false;
    },
    payToken(list) {
      this.selectedAuctionDetails = [];
      this.selectedAuctionDetails = list;
      this.selectedTokenAmount = list.tokenAmount;
      this.packageModal = true;
      this.getclaimamount(this.st.customerId);
    },

    async payTokenAmount(claimAmount, list) {


      this.packageModal = false;

      const packageListIds = this.selectedPackages.join(",");

      if (this.claimStatus == true && claimAmount) {
        // Perform the payment logic here
        if (claimAmount >= list.tokenAmount) {
        

          let cusid = this.st.customerId;
          userService
            .ClaimTokenAmount(list, cusid)
            .then((response) => {
              this.message = response.data.Message;
              if (this.message == "Success") {
                this.makeToast(
                  "success",
                  "Succesfully paid token amount. For more details, go to detail page by clicking on auction name"
                );

                setTimeout(() => {
                  this.$router
                    .push({
                      path: "/wonDetails",
                      params: {
                        url_key: list.aucName,
                      },
                    })
                    .catch((error) => {
                      console.error("Error navigating to /wonDetails:", error);
                    });
                }, 3000);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
        

          const result = await this.loadRazorPay();
          
          if (!result) {
            this.loading = false;
            alert("Failed to load razorpay script");
            return;
          }

          let cusid = this.st.customerId;
          userService
            .payTokenAmount(list, cusid, this.claimStatus, packageListIds)
            .then((response) => {
              this.paymentInfo = response.data.Data[0];
              this.reftoken = response.data.Data[0];
              this.message = response.data.Message;


             
              if (
                this.message == "Success" &&
                this.paymentInfo.rp_token != null
                //this.reftoken.tokenRefId != null
              ) {
               
                this.loading = false;
                const options = {
                  key: Config.rpayKey,
                  amount: this.paymentInfo.amount,
                  currency: Config.curency,
                  name: this.paymentInfo.cust_name,
                  description: this.paymentInfo.aucName,
                  order_id: this.paymentInfo.rp_token,
                  image: `/assets/images/menu/logo/2.png`,
                  handler: (response) => {
                    // It is function. executed on success
                    // console.log(self.claimStatus);
                    userService
                      .updOrders(response, 1, packageListIds)
                      .then((response) => {
                        this.message = response.data.Message;
                        if (this.message == "Success") {
                          this.loading = false;
                          this.makeToast(
                            "success",
                            "Succesfully paid token amount. For more details, go to detail page by clicking on auction name"
                          );

                          setTimeout(() => {
                            this.routTo();
                          }, 3000);
                        }
                      })
                      .catch((error) => {
                        this.loading = false;
                        this.isLoading = false;
                        this.message =
                          (error.response && error.response.data.Message) ||
                          error.message ||
                          error.toString();

                        this.makeToast("danger", "!Oops somthing went wrong");
                      });
                  },

                  prefill: {
                    name: this.paymentInfo.cust_name,
                    email: this.paymentInfo.cust_email,
                    contact: this.paymentInfo.cust_phone,
                  },
                  theme: {
                    color: "#3399cc",
                  },
                };
                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
              }
            })
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
        }
      } else {
        const result = await this.loadRazorPay();
        if (!result) {
          this.loading = false;
          alert("Failed to load razorpay script");
          return;
        }

        let cusid = this.st.customerId;
        userService
          .payTokenAmount(list, cusid, this.claimStatus, packageListIds)
          .then((response) => {
            this.paymentInfo = response.data.Data[0];
            this.message = response.data.Message;
            if (
              this.message == "Success" &&
              this.paymentInfo.rp_token != null
            ) {
              this.loading = false;
              const options = {
                key: Config.rpayKey,
                amount: this.paymentInfo.amount,
                currency: Config.curency,
                name: this.paymentInfo.cust_name,
                description: this.paymentInfo.aucName,
                order_id: this.paymentInfo.rp_token,
                image: `/assets/images/menu/logo/2.png`,
                // handler: function (response) {
                handler: (response) => {
                  // It is function. executed on success

                  userService
                    .updOrders(response, 0)
                    .then((response) => {
                      this.message = response.data.Message;
                      if (this.message == "Success") {
                        this.loading = false;
                        this.makeToast(
                          "success",
                          "Succesfully paid token amount. For more details, go to detail page by clicking on auction name"
                        );

                        setTimeout(() => {
                          this.routTo();
                        }, 3000);
                      }
                    })
                    .catch((error) => {
                      this.loading = false;
                      this.isLoading = false;
                      this.message =
                        (error.response && error.response.data.Message) ||
                        error.message ||
                        error.toString();
                      this.makeToast("danger", "!Oops somthing went wrong");
                    });
                },

                prefill: {
                  name: this.paymentInfo.cust_name,
                  email: this.paymentInfo.cust_email,
                  contact: this.paymentInfo.cust_phone,
                },
                theme: {
                  color: "#3399cc",
                },
              };
              const paymentObject = new window.Razorpay(options);
              paymentObject.open();
            }
          })
          .catch((e) => {
            this.loading = false;
            console.log(e);
          });
      }
    },
    async loadRazorPay() {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = this.script;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    },
    closeModal() {
      var self = this;

      setTimeout(function () {
        self.hideModal();
      }, 2000);
    },
    hideModal() {
      this.modelShow = false;
    },
    getCustomerWinnings(custId) {
      // console.log(custId);
      if (custId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getCustomerWinnings(custId)
          .then((response) => {
            this.customerList = response.data.Data;
            // console.log(this.customerList);
            this.initialLoading = false;
          })
          .catch((e) => {
            console.log(e);
            this.initialLoading = false;
          });
      }
    },
    getclaimamount(custId) {
      if (custId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getclaimamount(custId)
          .then((response) => {
            // console.log(response.data.Data);
            if (response.data.Data.length) {
              this.packageClaimList = response.data.Data;
              this.totalCliamAmount = this.packageClaimList.reduce(
                (total, item) => total + item.packAmount,
                0
              );
            } else {
              this.totalCliamAmount = 0;
            }

            this.initialLoading = false;
          })
          .catch((e) => {
            console.log(e);
            this.initialLoading = false;
          });
      }
    },
    makeToast(variant, body_content) {
      let tittleMessage = variant;
      if (variant == "danger") {
        tittleMessage = "Error";
      }
      if (variant == "success") {
        tittleMessage = "Success";
      }
      if (variant == "warning") {
        tittleMessage = "Warning";
      }
      this.$bvToast.toast(body_content, {
        title: `${tittleMessage || "default"}`,
        variant: variant,
        autoHideDelay: 5000,
        //solid: true,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });
    },
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format("D-MMM-YYYY hh:mm a");
      }
    },

    currency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      let country = Config.countryCode;
      let crncy = Config.curency;
      let FractionDigits = Config.minFractionDigits;
      const formatter = new Intl.NumberFormat(country, {
        style: "currency",
        currency: crncy,
        minimumFractionDigits: FractionDigits,
      });
      return formatter.format(value);
    },
  },
}; 
</script>
<style scoped>
th label {
  margin-bottom: 0px;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #fff;
  border: 1px solid #e1e5e9;
  padding: 0.35em;
  border-radius: 3px;
}

table thead tr:first-child {
  border: 1px solid #0094da;
}

table th,
table td {
  padding: 8px;
  text-align: left;
  color: #202020;
  font-size: 14px;
}

table td:nth-child(4) {
  font-size: 18px;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background: #0094da;
  color: #fff;
}

table tbody tr td .btn-invoice {
  background: #0094da;
  color: #fff;
  font-size: 13px;
  padding: 10px 20px;
  border: 0;
  width: 100%;
  border-radius: 5px;
}

tbody tr:nth-child(even) {
  background-color: #eee;
}

tbody tr:nth-child(odd) {
  background-color: #fff;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
    padding: 0;
  }

  table tr {
    border-bottom: 3px solid #e1e5e9;
    display: block;
    margin-bottom: 0.625em;
  }

  table th,
  table td {
    padding: 0.625em;
  }

  table td {
    border-bottom: 1px solid #e1e5e9;
    display: block;
    font-size: 0.8em;
    text-align: right;
    color: #9da9b9;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    color: #656971;
  }

  table td:last-child {
    border-bottom: 0;
  }

  table td:nth-child(4) {
    font-size: 0.8em;
  }
}

/* The Modal (background) */
.ebcf_modal {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  font-size: 60px;
}

/* Modal Content */
.ebcf_modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 350px;
  height: 280px;
  border-radius: 5px;
}

/* The Close Button */
.ebcf_close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.ebcf_close:hover,
.ebcf_close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-success .overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-success .modal-warning {
  z-index: 9999999;
  /* margin: 0 auto; */
  padding: 10px 10px;
  background-color: #fff;
  position: fixed;
  top: 130px;
  /* display: block; */
  /* height: 280px; */
  width: 360px;
  border-radius: 4px;
  font-size: 60px;
}

.modal-success .close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modalRejection {
  width: 400px !important;
}

.modal-container {
  /* width: 400px; */
  margin: 40px auto 0;
  padding: 15px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: -15px;
  color: #df765c;
}

.modal-header {
  border-bottom: none !important;
}

.modal-body {
  margin: 0;
  padding: 0px 15px;
}

.text-right {
  text-align: right;
}

.form-label {
  display: block;
  margin-bottom: 1em;
  font-size: larger;
}

.form-label>.form-control {
  margin-top: 0.5em;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5em 1em;
  line-height: 1.5;
  border: 1px solid #ddd;
}

.modal-footer {
  border-top: none !important;
}

.modal-enter,
.modal-leave {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 125px;
}

@media only screen and (max-width: 660px) {
  .line-clamp {
    width: unset !important;
  }
}

.btnClaim {
  color: #000 !important;
  background-color: #fff !important;
  border-color: #0dcaf0 !important;
}

.highlighted-row td {
  background-color: yellowgreen;
}
</style>
